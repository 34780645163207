<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Ujian
              </span>
            </h3>
            <div class="card-toolbar">
              <router-link v-if="!isSiswa && !isTeacher" :to="`/ujian/create`" href="#"
                class="btn btn-success font-weight-bolder font-size-sm">
                Tambah Ujian Baru</router-link>
            </div>
          </div>
          <div class="card-body pt-4">
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"
                    size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
                  label-size="sm" class="mb-0">
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"
                      placeholder="Type to Search"></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table :filter="filter" @filtered="onFiltered"
              :items="isSiswa ? currentMyUjian.data : currentUjianList.data" :fields="isSiswa ? fieldSiswa : fields"
              :busy="isLoading" show-empty class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                card-table
              " responsive>
              <template #empty>
                <h6 class="text-center">Tidak ada ujian ditemukan</h6>
              </template>
              <template #emptyfiltered>
                <h6 class="text-center">Tidak ada ujian ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #row-details="row">
                <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                  Deskripsi:
                </div>
                <div v-html="row.item.description"></div>
              </template>
              <template #cell(kode_ujian)="data">
                {{ data.item.exam_code }}
              </template>
              <template #cell(nama_ujian)="data">
                <!--                  {{ data.item.nama }}-->
                <!-- <div>
                  <a
                    href="#"
                    class="
                      text-dark-75
                      font-weight-bolder
                      text-hover-primary
                      mb-1
                      font-size-lg
                    "
                    >{{ data.item.nama }}</a
                  >
                  <span class="text-muted font-weight-bold d-block">{{
                    data.item.kurikulum.mapel.nama_mapel
                  }}</span>
                </div> -->
                <div>
                  {{ data.item.nama }}
                </div>
              </template>
              <template #cell(status)="data">
                <!--                <b-badge class="mr-1" pill href="#" variant="info">-->
                <!--                  {{ data.item.waktu }} Menit-->
                <!--                </b-badge>-->
                <button type="button" class="btn btn-sm btn-light-info font-weight-bold py-4 mr-1" disabled>
                  {{
                data.item.is_already_done
                  ? "Sudah dikerjakan"
                  : currentAttempt._id == data.item._id
                    ? "Sedang dikerjakan"
                    : "Belum dikerjakan"
              }}
                </button>
              </template>
              <template #cell(waktu)="data">
                <!--                <b-badge class="mr-1" pill href="#" variant="info">-->
                <!--                  {{ data.item.waktu }} Menit-->
                <!--                </b-badge>-->
                <span class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                    min-w-80px
                  ">
                  {{ data.item.time }} Menit
                </span>
              </template>
              <template #cell(tanggal)="data">
                <span class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-success
                    mr-1
                  ">
                  {{ data.item.opening_time | moment("dddd, LL HH:mm") }}
                </span>
                -
                <span class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-danger
                    ml-1
                  ">
                  {{ data.item.closing_time | moment("dddd, LL HH:mm") }}
                </span>
              </template>
              <template #cell(published)="data">
                <b-form-checkbox v-model="data.item.is_published" @input="togglePublish(data.item)" name="check-button"
                  switch>
                </b-form-checkbox>
              </template>
              <template #cell(action)="data">
                <button :disabled="currentAttempt._id ? true : false" @click="attemptUjian(data.item)" v-if="isSiswa &&
                new Date() >= Date.parse(data.item.opening_time) &&
                new Date() <=
                new Date(Date.parse(data.item.closing_time)).setMinutes(
                  new Date(
                    Date.parse(data.item.closing_time)
                  ).getMinutes() - data.item.time
                )
                " class="
                    btn btn-light-primary
                    font-weight-bolder font-size-sm
                    mr-1
                  ">{{ data.item.is_already_done
                ? "Sudah dikerjakan"
                : currentAttempt._id == data.item._id
                  ? "Sedang dikerjakan" : "Mulai ujian" }}</button>

                <b-dropdown v-if="!isSiswa" variant="light"
                  toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon mr-2" no-caret right
                  no-flip>
                  <template v-slot:button-content>
                    <i class="flaticon2-file text-primary"></i>
                  </template>

                  <div class="navi navi-hover min-w-md-250px">
                    <!-- <b-dropdown-text tag="div" class="navi-item">
                      <router-link class="navi-link" :to="`/ujian/${data.item._id}/soal`">
                        <span class="navi-icon">
                          <i class="flaticon-interface-3"></i>
                        </span>
                        <span class="navi-text">Detail Ujian</span>
                      </router-link>
                    </b-dropdown-text> -->
                    <b-dropdown-text tag="div" class="navi-item">
                      <router-link class="navi-link" :to="`/rekap-nilai/${data.item._id}`">
                        <span class="navi-icon">
                          <i class="flaticon-doc"></i>
                        </span>
                        <span class="navi-text">Rekap Nilai</span>
                      </router-link>
                    </b-dropdown-text>
                    <!-- && -->
                    <!-- new Date() > Date.parse(data.item.closing_time) -->
                    <b-dropdown-text v-if="!isSiswa

                " tag="div" class="navi-item">
                      <router-link class="navi-link" :to="`/ujian/${data.item._id}/penilaian/`">
                        <span class="navi-icon">
                          <i class="flaticon-graphic"></i>
                        </span>
                        <span class="navi-text">Penilaian</span>
                      </router-link>
                    </b-dropdown-text>
                    <!-- <b-dropdown-text v-if="!isSiswa" tag="div" class="navi-item">
                      <router-link class="navi-link" :to="`/preview/ujian/${data.item._id}`">
                        <span class="navi-icon">
                          <i class="flaticon2-writing"></i>
                        </span>
                        <span class="navi-text">Pratinjau ujian</span>
                      </router-link>
                    </b-dropdown-text> -->
                  </div>
                </b-dropdown>
                <b-dropdown v-if="!isSiswa" variant="light"
                  toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon" no-caret right
                  no-flip>
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <router-link class="navi-link" :to="`/ujian/${data.item._id}/edit`">
                        <span class="navi-icon">
                          <i class="flaticon2-edit"></i>
                        </span>
                        <span class="navi-text">Ubah Ujian</span>
                      </router-link>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <span class="navi-link cursor-pointer" @click="deleteUjian(data.item)">
                        <span class="navi-icon">
                          <i class="flaticon2-trash"></i>
                        </span>
                        <span class="navi-text">Hapus Ujian</span>
                      </span>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
              class="my-0"></b-pagination>
          </div>
          <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import {
  DELETE_UJIAN,
  GET_UJIAN,
  GET_MY_UJIAN,
  UPDATE_UJIAN,
} from "@/core/services/store/ujian.module";
import { GET_MAPEL } from "@/core/services/store/mapel.module";
import { GET_ONE_ATTEMPT } from "@/core/services/store/attempt.module";
export default {
  name: "UjianLIst",
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      fieldSiswa: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "exam_code",
          label: "Kode ujian",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama ujian",
          sortable: true,
        },
        {
          key: "waktu",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "tanggal",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "exam_code",
          label: "Kode ujian",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama ujian",
          sortable: true,
        },
        // {
        //   key: 'waktu',
        //   label: "Waktu",
        //   sortable: true
        // },
        {
          key: "tanggal",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "published",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      selectedMapel: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar ujian" }]);
    if (!this.isSiswa) {
      this.getData();
    } else {
      this.getDataSiswa();
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters([
      "currentUjianList",
      "currentMapelList",
      "currentAttempt",
      "currentMyUjian",
    ]),
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    options() {
      var opt = [];
      // this.currentMapelList.forEach((mapel) => {
      //   opt.push({
      //     text:
      //       mapel.kurikulum.mapel.nama_mapel +
      //       " - Tingkat " +
      //       parseInt(mapel.kurikulum.semester / 2 + 1),
      //     value: mapel.id_kurikulum,
      //   });
      // });
      return opt;
    },
    currentUser() {
      return this.$currentUser();
    },
    isSiswa() {
      return this.$currentUser().role == "student";
    },
    isTeacher() {
      return this.$currentUser().role == "teacher";
    },
  },
  watch: {
    perPage: function () {
      if (!this.isSiswa) {
        this.getData();
      } else {
        this.getDataSiswa();
      }
    },
    currentPage: function () {
      if (!this.isSiswa) {
        this.getData();
      } else {
        this.getDataSiswa();
      }
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getMapel() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_MAPEL)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Mata Pelajaran`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getData() {
      this.isLoading = true;

      const params = new URLSearchParams();
      params.append("limit", this.perPage);
      params.append("page", this.currentPage);
      this.$store
        .dispatch(GET_UJIAN, params.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentUjianList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getDataSiswa() {
      this.isLoading = true;

      const params = new URLSearchParams();
      params.append("limit", this.perPage);
      params.append("page", this.currentPage);
      params.append("is_already_done", 0);

      this.$store
        .dispatch(GET_MY_UJIAN, params.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentMyUjian.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    togglePublish(item) {
      // item.published = !item.published
      this.isLoading = true;

      this.$delete(item, "class_id");
      var newValue = item;

      newValue.is_published = item.is_published;

      this.$store
        .dispatch(UPDATE_UJIAN, newValue)
        .then((response) => {
          this.isLoading = false;
          this.$bvToast.toast(
            `Berhasil ${item.is_published ? "publish" : "unpublish"} ujian`,
            {
              title: response.message,
              variant: "success",
              solid: true,
            }
          );
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Publish Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    attemptUjian(item) {
      const overlay = this.$loadingOverlay.show();
      this.$store
        .dispatch(GET_ONE_ATTEMPT, item._id)
        .then(() => {
          overlay.hide();
          this.$router.push("attempt");
        })
        .catch(() => {
          overlay.hide();
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    deleteUjian(item) {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus ujian ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(DELETE_UJIAN, item._id)
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus ujian`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Publish Ujian`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
  },
};
</script>